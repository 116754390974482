import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import useAuthStore from '../../stores/useAuthStore';
import { FaCog, FaBell, FaFire, FaGem, FaHeart, FaEnvelope, FaUserEdit, FaSignOutAlt, FaUser } from 'react-icons/fa';
import { IoArrowBack, IoChevronDown } from 'react-icons/io5';
import ProgressDonut from '../practice_v2/ProgressDonut';

function WorkspaceHeader({ lessonTitle, lessonId }) {
  const { user, logout } = useAuthStore();
  const navigate = useNavigate();
  const [notifications] = useState(3);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const menuRef = useRef(null);
  const [showNotifications, setShowNotifications] = useState(false);
  const notificationRef = useRef(null);
  const headerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setShowNotifications(false);
      }
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowProfileMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <motion.header
      ref={headerRef}
      className="fixed w-full z-50 h-16 bg-premium-dark backdrop-blur-md border-b border-white/[0.03] shadow-premium"
      initial={{ y: -100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        type: "spring",
        stiffness: 300,
        damping: 30,
        mass: 1.2
      }}
    >
      <div className="h-full w-full flex items-center px-4">
        {/* Left Section */}
        <div className="flex items-center space-x-6">
          <motion.button
            whileHover={{ scale: 1.02, x: -2 }}
            whileTap={{ scale: 0.98, x: 0 }}
            onClick={() => navigate('/journey')}
            className="flex items-center text-slate-400 hover:text-white transition-all duration-300 ease-in-out
                      px-2 py-1 rounded-full bg-slate-800/30 hover:bg-slate-800/50
                      border border-white/[0.02] hover:border-white/[0.05] shadow-sm text-xs group"
          >
            <motion.div
              animate={{ x: [0, -3, 0] }}
              transition={{
                repeat: Infinity,
                repeatType: "mirror",
                duration: 1.5,
                repeatDelay: 3
              }}
              className="group-hover:text-blue-400"
            >
              <IoArrowBack className="w-3 h-3 mr-1.5" />
            </motion.div>
            <span className="font-medium text-xs">Journey</span>
          </motion.button>

          <motion.div
            className="h-5 w-px bg-slate-700"
            initial={{ height: 0 }}
            animate={{ height: "1.25rem" }}
            transition={{ delay: 0.2, duration: 0.4 }}
          />

          <motion.div
            whileHover={{ rotate: 10, scale: 1.05 }}
            initial={{ rotate: -10, scale: 0.9, opacity: 0 }}
            animate={{ rotate: 0, scale: 1, opacity: 1 }}
            transition={{ delay: 0.3, type: "spring", stiffness: 400 }}
          >
            <Link to="/">
              <motion.div className="relative">
                <motion.div
                  animate={{
                    opacity: [0.3, 0.7, 0.3],
                    scale: [1, 1.1, 1]
                  }}
                  transition={{
                    repeat: Infinity,
                    duration: 3,
                    ease: "easeInOut"
                  }}
                  className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-indigo-500/20 rounded-full blur-md"
                />
                <motion.img
                  src="https://storage.googleapis.com/mosaic-images/Mosaic%20Logo.webp"
                  alt="Mosaic Logo"
                  className="w-4 h-4 md:w-5 md:h-5 object-cover opacity-70 hover:opacity-100 transition-opacity relative z-10"
                  whileHover={{ rotate: 360 }}
                  transition={{ duration: 0.7 }}
                />
              </motion.div>
            </Link>
          </motion.div>

          {(lessonId || lessonTitle) && (
            <div className="flex items-center space-x-3">
              <motion.div
                className="flex items-center space-x-2 px-3 py-1 bg-gradient-to-r from-slate-800/20 to-slate-900/20
                          rounded-full border border-white/[0.03] text-xs"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.4, duration: 0.5 }}
              >
                <span className="text-slate-400">Lesson {lessonId}</span>
                <span className="text-slate-500">•</span>
                <motion.span
                  className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-indigo-500 font-medium"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.7, duration: 0.5 }}
                >
                  {lessonTitle}
                </motion.span>
              </motion.div>

              {lessonId && (
                <motion.div
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.6, duration: 0.5 }}
                  className="relative flex items-center"
                >
                  <div className="relative group">
                    <ProgressDonut
                      lessonId={lessonId}
                      size={28}
                      variant="header"
                    />
                    <motion.div
                      initial={{ opacity: 0 }}
                      whileHover={{ opacity: 1 }}
                      className="absolute inset-0 rounded-full bg-gradient-to-r from-blue-500/10 to-indigo-500/10 blur-xl transition-opacity duration-300"
                    />
                  </div>
                </motion.div>
              )}
            </div>
          )}
        </div>

        {/* Center Section - Removed progress donut */}
        <div className="flex-1" />

        {/* Right Section */}
        <div className="flex items-center space-x-6">
          {/* Notifications */}
          <div className="relative" ref={notificationRef}>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setShowNotifications(!showNotifications)}
              className="relative p-2 text-slate-400 hover:text-white transition-colors rounded-full hover:bg-white/[0.03]"
            >
              <FaBell className="w-5 h-5" />
              {notifications > 0 && (
                <motion.span
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ delay: 1, type: "spring", stiffness: 500 }}
                  className="absolute -top-1 -right-1 bg-gradient-to-r from-blue-500 to-indigo-500 text-xs text-white w-4 h-4 rounded-full flex items-center justify-center shadow-lg"
                >
                  {notifications}
                </motion.span>
              )}
            </motion.button>

            <AnimatePresence>
              {showNotifications && (
                <motion.div
                  initial={{ opacity: 0, y: 10, scale: 0.95 }}
                  animate={{ opacity: 1, y: 0, scale: 1 }}
                  exit={{ opacity: 0, y: 10, scale: 0.95 }}
                  transition={{ duration: 0.2, ease: [0.23, 1, 0.32, 1] }}
                  className="absolute right-0 mt-2 w-80 rounded-xl shadow-2xl
                            bg-gradient-to-br from-slate-800/95 to-slate-900/95
                            backdrop-blur-md ring-1 ring-black/5 border border-white/[0.05]"
                >
                  <div className="p-3 border-b border-slate-700/50 flex items-center justify-between">
                    <h3 className="text-sm font-medium text-white">Notifications</h3>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="text-xs text-slate-400 hover:text-slate-200 bg-slate-800/50 px-2 py-1 rounded-md transition-all"
                    >
                      Mark all as read
                    </motion.button>
                  </div>
                  <div className="max-h-96 overflow-y-auto">
                    {[
                      {
                        id: 1,
                        title: "New Achievement Unlocked!",
                        message: "You've completed your first lesson 🎉",
                        time: "2 hours ago",
                        icon: <FaGem className="text-blue-400" />,
                        isNew: true
                      },
                      {
                        id: 2,
                        title: "Profile Update",
                        message: "Your profile has been successfully updated",
                        time: "1 day ago",
                        icon: <FaUserEdit className="text-green-400" />,
                        isNew: true
                      },
                      {
                        id: 3,
                        title: "New Message",
                        message: "You received feedback on your latest submission",
                        time: "2 days ago",
                        icon: <FaEnvelope className="text-purple-400" />,
                        isNew: false
                      }
                    ].map((notification, index) => (
                      <motion.div
                        key={notification.id}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: index * 0.1 }}
                        className={`p-4 hover:bg-slate-700/30 transition-colors border-b border-slate-700/30
                                  ${notification.isNew ? 'bg-blue-500/5' : ''}`}
                      >
                        <div className="flex items-start space-x-3">
                          <motion.div
                            whileHover={{
                              rotate: [0, -10, 10, -5, 0],
                              scale: 1.05
                            }}
                            transition={{ duration: 0.5 }}
                            className="p-2 bg-slate-700/30 rounded-full"
                          >
                            {notification.icon}
                          </motion.div>
                          <div>
                            <div className="flex items-center">
                              <p className="text-sm font-medium text-white">{notification.title}</p>
                              {notification.isNew && (
                                <span className="ml-2 px-1.5 py-0.5 text-[10px] bg-blue-500/20 text-blue-300 rounded-md">
                                  New
                                </span>
                              )}
                            </div>
                            <p className="text-xs text-slate-400 mt-1">{notification.message}</p>
                            <p className="text-xs text-slate-500 mt-1">{notification.time}</p>
                          </div>
                        </div>
                      </motion.div>
                    ))}
                  </div>
                  <div className="p-2 text-center border-t border-slate-700/50">
                    <motion.button
                      whileHover={{ scale: 1.03, y: -1 }}
                      whileTap={{ scale: 0.97 }}
                      className="text-xs bg-gradient-to-r from-blue-500/10 to-indigo-500/10
                              text-blue-400 hover:text-blue-300 px-3 py-1.5 w-full rounded-lg
                              border border-blue-500/10 hover:border-blue-500/20 transition-all duration-200"
                    >
                      View All Notifications
                    </motion.button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          {/* Settings */}
          <motion.button
            whileHover={{ scale: 1.05, rotate: 15 }}
            whileTap={{ scale: 0.95, rotate: 0 }}
            onClick={() => navigate('/settings')}
            className="text-slate-400 hover:text-white transition-colors"
          >
            <FaCog className="w-5 h-5" />
          </motion.button>

          {/* User Menu - Modified */}
          <div className="relative pl-4 border-l border-slate-700" ref={menuRef}>
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => setShowProfileMenu(!showProfileMenu)}
              className="flex items-center space-x-3"
            >
              <motion.div
                initial={{ x: 20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.7, duration: 0.5 }}
                className="text-right"
              >
                <p className="text-sm font-medium text-white">{user?.email?.split('@')[0]}</p>
              </motion.div>
              <motion.div
                animate={{ rotate: showProfileMenu ? 180 : 0 }}
                transition={{ duration: 0.3 }}
              >
                <IoChevronDown className="w-4 h-4 text-slate-400" />
              </motion.div>
            </motion.button>

            <AnimatePresence>
              {showProfileMenu && (
                <motion.div
                  initial={{ opacity: 0, y: 10, scale: 0.95 }}
                  animate={{ opacity: 1, y: 0, scale: 1 }}
                  exit={{ opacity: 0, y: 10, scale: 0.95 }}
                  transition={{ duration: 0.2, ease: [0.23, 1, 0.32, 1] }}
                  className="absolute right-0 mt-2 w-60 rounded-xl shadow-2xl
                           bg-gradient-to-br from-slate-800/95 to-slate-900/95
                           backdrop-blur-md ring-1 ring-black/10 border border-white/[0.05]"
                >
                  <div className="p-4 border-b border-slate-700/50 bg-gradient-to-r from-blue-500/5 to-indigo-500/5">
                    <div className="flex items-center space-x-3">
                      <div className="w-10 h-10 rounded-full bg-gradient-to-r from-blue-500/20 to-indigo-500/20
                                   border border-white/[0.08] flex items-center justify-center">
                        <FaUser className="w-5 h-5 text-blue-400" />
                      </div>
                      <div>
                        <p className="text-sm font-medium text-white">{user?.email?.split('@')[0]}</p>
                        <p className="text-xs text-slate-400 truncate max-w-[170px]">{user?.email}</p>
                      </div>
                    </div>
                  </div>

                  <div className="p-3 border-b border-slate-700/50">
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.1 }}
                      className="flex items-center justify-between mb-2 group"
                    >
                      <div className="flex items-center space-x-2">
                        <motion.div
                          whileHover={{ scale: 1.1, rotate: 15 }}
                          transition={{ type: "spring", stiffness: 500 }}
                          className="text-orange-500 group-hover:text-orange-400 transition-colors"
                        >
                          <FaFire />
                        </motion.div>
                        <span className="text-slate-200 text-sm">Streak</span>
                      </div>
                      <motion.span
                        whileHover={{ scale: 1.1 }}
                        className="text-white font-medium px-2 py-0.5 bg-orange-500/10 rounded-md"
                      >
                        3
                      </motion.span>
                    </motion.div>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.2 }}
                      className="flex items-center justify-between mb-2 group"
                    >
                      <div className="flex items-center space-x-2">
                        <motion.div
                          whileHover={{ scale: 1.1, rotate: 15 }}
                          transition={{ type: "spring", stiffness: 500 }}
                          className="text-blue-400 group-hover:text-blue-300 transition-colors"
                        >
                          <FaGem />
                        </motion.div>
                        <span className="text-slate-200 text-sm">Points</span>
                      </div>
                      <motion.span
                        whileHover={{ scale: 1.1 }}
                        className="text-white font-medium px-2 py-0.5 bg-blue-500/10 rounded-md"
                      >
                        3,440
                      </motion.span>
                    </motion.div>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.3 }}
                      className="flex items-center justify-between group"
                    >
                      <div className="flex items-center space-x-2">
                        <motion.div
                          whileHover={{ scale: 1.1, rotate: 15 }}
                          transition={{ type: "spring", stiffness: 500 }}
                          className="text-red-500 group-hover:text-red-400 transition-colors"
                        >
                          <FaHeart />
                        </motion.div>
                        <span className="text-slate-200 text-sm">Lives</span>
                      </div>
                      <motion.span
                        whileHover={{ scale: 1.1 }}
                        className="text-white font-medium px-2 py-0.5 bg-red-500/10 rounded-md"
                      >
                        5
                      </motion.span>
                    </motion.div>
                  </div>

                  <div className="py-1" role="menu">
                    <motion.button
                      onClick={() => navigate(`/user-profile/${user?.id}`)}
                      whileHover={{ x: 4, backgroundColor: "rgba(255,255,255,0.03)" }}
                      className="w-full text-left px-4 py-2 text-sm text-slate-300 hover:text-white transition-colors"
                      role="menuitem"
                    >
                      <div className="flex items-center">
                        <FaUser className="mr-2 text-blue-400" size={12} />
                        Profile
                      </div>
                    </motion.button>
                    <motion.button
                      whileHover={{ x: 4, backgroundColor: "rgba(255,255,255,0.03)" }}
                      className="w-full text-left px-4 py-2 text-sm text-slate-300 hover:text-white transition-colors"
                      role="menuitem"
                    >
                      <div className="flex items-center">
                        <FaEnvelope className="mr-2 text-green-400" size={12} />
                        Help
                      </div>
                    </motion.button>
                    <div className="h-px bg-slate-700/50 my-1" />
                    <motion.button
                      onClick={handleLogout}
                      whileHover={{ x: 4, backgroundColor: "rgba(239,68,68,0.05)" }}
                      className="w-full text-left px-4 py-2 text-sm text-red-400 hover:text-red-300 transition-colors"
                      role="menuitem"
                    >
                      <div className="flex items-center">
                        <FaSignOutAlt className="mr-2" size={12} />
                        Log Out
                      </div>
                    </motion.button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </motion.header>
  );
}

export default WorkspaceHeader;